import type { NewsletterSubscription, RegisteredInterest } from '@/common/api/mail/types'
import { postJSONRequest } from '@/services/postJSONRequest'

export type SubscriptionData = {
  access?: boolean
  email: string
  events?: boolean
  news?: boolean
  tags?: string[] | null
}

export type InquireData = {
  firstName: string
  lastName: string
  phone?: string
  comments?: string
} & SubscriptionData

const sanitizeTags = (tags: string[] | null | undefined) => {
  if (Array.isArray(tags) && tags.length > 0) return tags
  return undefined
}
const endpointUrl = '/api/user/subscribe'

export const sendSubscribeRequest = async (data: SubscriptionData, sourceUrl?: string) => {
  const { email, access, events, news, tags } = data

  const payload: NewsletterSubscription = {
    type: 'newsletter',
    email,
    interests: { access, events, news },
    sourceUrl,
    tags: sanitizeTags(tags),
  }

  return postJSONRequest({
    context: 'Subscribe request',
    data: payload,
    endpointUrl,
  }).then((result) => ({ isSuccess: !result?.error, error: result?.error }))
}

export type RegisteredInterestData = {
  access?: boolean
  ctaText?: string | null
  currentUrl?: string
  firstName: string
  lastName: string
  phone?: string
  email: string
  events?: boolean
  formId: 'inquiry'
  id?: string
  inquiryCategory?: string
  inquiryType?: string
  landingPageURL?: string
  news?: boolean
  pageTitle?: string
  tags?: string[] | null
  timestamp?: number
  comments?: string
}

export const sendRegisteredInterestRequest = async (data: RegisteredInterestData) => {
  const {
    ctaText,
    currentUrl,
    email,
    events,
    formId,
    id,
    inquiryCategory,
    inquiryType,
    landingPageURL,
    pageTitle,
    tags,
    timestamp,
    firstName,
    lastName,
    phone,
    comments,
    // The RI form has no checkboxes for these fields, but we need them to be true
    // @link https://linear.app/zwirner/issue/WEB-3562/[braze]-extend-ri-data-sent-to-braze
    // access is Sales, news are News.
    access = true,
    news = true,
  } = data

  const payload: RegisteredInterest = {
    type: 'registeredInterest',
    ctaText,
    currentUrl,
    firstName,
    lastName,
    phone,
    email,
    formId,
    id,
    inquiryCategory,
    inquiryType,
    interests: { access, events, news },
    landingPageURL,
    pageTitle,
    tags: sanitizeTags(tags),
    timestamp,
    comments,
  }

  return postJSONRequest({
    context: 'Send inquiry',
    data: payload,
    endpointUrl,
  }).then((result) => ({ isSuccess: !result?.error, error: result?.error }))
}
